#intro-convenios {
    background:url(../../assets/images/intro-convenios.png) no-repeat top center;
    min-height: 348px;
    color: #fff;
    margin-top: 30px;
}

#intro-convenios .container {
    padding: 70px;
}

#intro-convenios .container h1 {
    font-size: 46px;
    line-height: 56px;
    font-weight: 600;
    max-width: 64%;
    margin-bottom: 60px;
}

#intro-convenios .container p {
    margin-bottom: 7px;
    font-size: 18px;
    color: #D9D9D9;
}

#convenios-nao-encontrado {
    text-align: center;
    background:url(../../assets/images/waves2.png) no-repeat top center;
    padding-top: 150px;
}

#convenios-nao-encontrado  p {
    font-size: 18px;
}

#convenios-nao-encontrado .txt-destaque {
    text-align: left;
    padding-top:70px;
}

#convenios-nao-encontrado h3 {
    font-size: 38px;
    font-weight: 600;
    margin-bottom: 23px;
}

#convenios-nao-encontrado .txt-destaque h3,
#convenios-nao-encontrado .txt-destaque span {
    display: none;
}

#convenios-nao-encontrado .txt-destaque p {
    max-width: 68%;
}

#convenios-nao-encontrado #condicoes-pagamento {
    padding: 40px 0 50px;
}

#convenios-nao-encontrado #condicoes {
    display: none;
}

.page-convenios #como-funciona-interna:before {
    display: none;
}

.page-convenios #como-funciona-interna {
    padding-top: 70px !important;
}

#convenios .slick-dots {
    bottom: 0;
}


.page-convenios {
    background: url(../../assets/images/fundo-convenios.png) no-repeat top center;
    background-size:100%;
    background-position-y: 120px;
}

@media (max-width:768px) {

    
    #intro-convenios {
        background: #001F85 url(../../assets/images/dots-mobile.svg) no-repeat 25px 25px !important;
    }

    #intro-convenios .container p span {
        display: block;
    }

    .page-convenios #intro-convenios .container h1 {
        padding: 0 10px;
    }
    
    #intro-convenios .container p {   
        padding: 0 10px;
    }

    #convenios-nao-encontrado .txt-destaque img{
        margin: 25px auto 0;
    }
}

@media (max-width:440px) {
    #intro-convenios .container p {
        padding: 0;
    }
}