#pageAplicativos:after {
    content:'';
    position: absolute;
    width: 100%;
    height:660px;
    background:url(../../assets/images/apps.png) no-repeat top center;
    top: 0;
    z-index: -1;
}

#sobre-app{
    padding: 100px 0;
}
#sobre-app  h2{
    font-weight: 600;
    font-size: 38px;
    line-height: 52px;
    text-align: center;
    color: #000000;
    margin: 0 0 30px;
}
#sobre-app  p{
    font-weight: 400;
    font-size: 18px;
    line-height: 29px;
    color: #474747;
    margin: 0 0 40px;
    text-align: center;
}
#sobre-app  ul{
    list-style: none;
    margin: 0;
    padding: 0;
}
#sobre-app  ul li{
    display: flex;
    align-items: center;
    margin-bottom: 32px;
    font-weight: 600;
    font-size: 18px;
    line-height: 34px;
    color: #001F85;
}
#sobre-app  ul li img{
    margin-right: 8px;
}

@media (max-width: 768px) {
    #pageAplicativos:after {
        display: none;
    }
    #sobre-app  h2{
        font-size: 32px;
        line-height: 160%;
    }
    #sobre-app .img-app{
        display: block;
        margin: 0 auto 30px;
    }
    #sobre-app  ul{
        width: fit-content;
        margin: 0 auto;
    }
}