@import url(../node_modules/bootstrap/dist/css/bootstrap.min.css);
@import url(assets/css/animate.min.css);
@import url(assets/css/slick.css);
@import url(assets/css/slick-theme.css);
body {    
  font-family: 'Poppins', sans-serif;
  overflow-x: hidden;
}

.btn-primary {
  background:#FF2763;
  text-decoration: none;
  max-width: 384px;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  border: 0;
  border-radius: 50px;
  font-weight: bold;
  font-size: 18px;
  color: #ffffff;
}

.btn-primary:hover {
  background:#E6265C;
  color: #ffffff;
}

.btn-secondary {
  background:#001F85;
  text-decoration: none;
  width: 384px;
  height: 56px;
  border-radius: 50px;
  color: #fff;
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 15px;
  letter-spacing: 0.7px;
  font-weight: bold;
}


.btn-secondary:hover {
  background:#001A70;
  color: #fff;
}

.desktop-version {
  display: flex;
}

.mobile-version {
  display: none;
}

@media (min-width: 1600px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1216px;
      padding: 0;
  }
}


@media (max-width: 768px) {
  .desktop-version {
    display: none !important;
  }
  
  .mobile-version {
    display: flex !important;
    overflow: hidden;
  }
}