#page-home:after {
    content:'';
    position: absolute;
    width: 100%;
    height:498px;
    background:url(../../assets/images/intro.png) no-repeat top center;
    top: 0;
    z-index: -1;
}

#page-home #como-funciona .bx-facilidades::before {
    left: 107px;
}

#page-home #como-funciona .bx-facilidades:last-child::before {
    display: none;
}

@media (max-width:768px) {
    #page-home:after {
        display: none;
    }
}

