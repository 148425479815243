#diferenciais {
    position: relative;
    z-index: 99999;
}

#diferenciais .container {
    background: #001F85;
    min-height: 678px;
    border-radius: 20px;
    padding: 104px;
    text-align: center;
    color: #fff;
    position: relative;
}

#diferenciais .container:before {
    content: '';
    background: url(../../assets/images/dots.png) no-repeat;
    width: 126px;
    height: 118px;
    position: absolute;
    top: 73px;
    left: 124px;
}

#diferenciais .container span.sub {
    color: #D9D9D9;
    font-size: 14px;
}

#diferenciais h3 {
    margin-bottom: 80px;
    font-size: 38px;
    font-weight: 500;
}

#diferenciais ul {
    list-style: none;
    padding: 0;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 0;
}

#diferenciais ul li {
    width: 42%;
    text-align: left;
    font-size: 22px;
    color: #D9D9D9;
    font-weight: 500;
    line-height: 32px;
    margin-bottom: 48px;
}

#diferenciais ul li svg path {
    fill: #3282E4;
}

#diferenciais ul li:nth-child(5),
#diferenciais ul li:nth-child(6) {
    margin-bottom: 0;
}

#diferenciais ul li svg {
    margin-right: 20px;
    display: inline-block;
    vertical-align: middle;
}


#diferenciais ul li p {
    display: inline-block;
    max-width: calc(100% - 105px);
    margin-bottom: 0 !important;
    padding-bottom: 0;
    align-items: center;
    flex-wrap: wrap;
    line-height: 32px;
    vertical-align: middle;
}

#diferenciais ul li p span {
    display: block;
}

@media (max-width:768px) {
    #diferenciais {
        overflow: hidden;
    }
    #diferenciais h3 {
        font-size: 32px;
        margin-bottom: 0 !important;
        padding:0 20px;
    }

    #diferenciais .container:before {
        background: url(../../assets/images/dots-mobile.svg) no-repeat;
        left: 45px;
        top: 30px;
    }

    #diferenciais .container {
        padding: 100px 0 0 0;
        max-width: 1216px;
        min-height: auto;
    }

    #diferenciais .card-diferenciais {
        background: #001F85;
        box-shadow: 0px 4px 50px rgba(50, 130, 228, 0.5);
        border-radius: 10px;
        height: 186px;
        width: 214px;
        padding: 16px;
    }

    #diferenciais p {
        margin-top: 16px;
        font-family: Poppins, sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 26px;
        color: #D9D9D9;
        text-align: left;
    }

    #diferenciais .slick-dots li button:before {
        color: #D9D9D9;
        opacity: 1;
        font-size: 10px;
    }

    #diferenciais .slick-dots li.slick-active button:before {
        color: #FF2763;
    }

    #diferenciais .slick-slide {
        margin-top: 1rem;
        padding: 40px 10px;
        box-sizing: border-box;
    }

    #diferenciais .slick-dots li {
        margin-bottom: 18px;
        width: auto;
    }

    #diferenciais .mobile-version {
        padding-bottom: 75px;
    }
}