#todas-vacinas {
    position: relative;
    z-index: 9;
}

#todas-vacinas .col-md-3 {
    margin-bottom: 80px
}

#exames-mais-buscados .card-vacinas {
    padding:48px;
    border: 2px solid #E7F0FF;
    box-sizing: border-box;
    box-shadow: 0px 10px 50px rgba(50, 130, 228, 0.2);
    border-radius: 20px;
    height: 100%;
    justify-content: space-between;
}

#todas-vacinas .card {
    background: #FFFFFF;
    box-shadow: 0px 10px 50px rgba(50, 130, 228, 0.2);
    border-radius: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    height: 169px;
    cursor: pointer;
    color: #000000;
    font-size: 20px;
    font-weight: bold;
}

#todas-vacinas .card p {
    margin-top:20px;
    margin-bottom: 0;
}

#todas-vacinas .card.active {
    background: #FFEAF0;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 20px 50px rgba(230, 38, 92, 0.2);
}

#todas-vacinas .card:hover {
    background: #FFEAF0;
    border: 1px solid #FFFFFF;
    box-shadow: 0px 20px 50px rgba(230, 38, 92, 0.2);
}

#todas-vacinas ul {
    list-style: none;
    padding: 0;
    margin-bottom: 50px;
}

#todas-vacinas ul li {
    display: flex;
    border-bottom: 1px solid #E7F0FF;
    padding:35px 150px 20px;
}

#todas-vacinas ul li:last-child {
    border-bottom: 0;
}

#todas-vacinas ul li p {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    color:#474747;
    font-size: 18px;
    text-align: left;
    margin-bottom: 20px;
}

#todas-vacinas ul li p .link-button {
    font-size: 16px;
    font-weight: 600;
    color: #FF2763;
    opacity: .9;
    margin-top: 1rem;
}

#todas-vacinas ul li p .link-button:hover {
    opacity: 1;
}

#todas-vacinas ul li p strong {
    font-size: 20px;
    font-weight: 600;
    color:#000;
}

#todas-vacinas .btn {
    margin:0 auto;
}

#todas-vacinas h2 {
    text-align: center;
    font-weight: 600;
    margin-bottom: 22px;
}

#todas-vacinas p {
    text-align: center;
    margin-bottom: 60px;
}

#sobre.sobre-vacinas:before {
    content:'';
    width: 100%;
    display: block;
    position:absolute;
    background:url(../../assets/images/exames-waves.png) no-repeat top center;
    background-size:cover;
    height: 313px !important;
    top:-200px;
}


#sobre.sobre-vacinas .btn-secondary {
    background: #001F85;    
}

@media (max-width:600px) {
    #todas-vacinas .col-md-3 {
        margin-bottom: 20px;
    }

    #todas-vacinas ul li {
        padding: 35px 20px 20px;
    }

    #todas-vacinas .card {
        flex-direction: initial;
        justify-content: flex-start;
        padding-left: 50px;
        height: auto;
    }

    #todas-vacinas .card .icon {
        margin-right: 30px;
    }

    #todas-vacinas .card p {
        margin-top:0;
    }

    .intro-vacinas p {
        padding: 0 8% !important;
        font-size: 18px !important;
        color:#D9D9D9;
    }

    #como-funciona-interna.como-funciona-vacinas h4 {
        padding: 0 10%;
        font-size: 20px;
    }

    #sobre.sobre-vacinas {
        margin-top: 50px !important;
    }

    #exames-mais-buscados .slick-dots li button:before {
        color: #D9D9D9;
        opacity: 1;
        font-size: 10px;
    }

    #exames-mais-buscados .slick-dots li.slick-active button:before {
        color: #001F85
    }

    #exames-mais-buscados h3 {
        margin: 12px 0;
    }

    #exames-mais-buscados .slick-slide {
        margin-top: 3rem;
        padding: 0 10px;
        box-sizing: border-box;
    }

    #exames-mais-buscados .card-vacinas {
        height: 340px;
        background: #FFFFFF;
        border-radius: 20px;
    }

    #todas-vacinas .btn {
        max-width: 100%;
        margin-bottom: 50px;
    }
}

@media (max-width:440px) {
    #exames-mais-buscados .card-vacinas {
        height: auto;
        padding: 20px;
    }

    #exames-mais-buscados .card-vacinas p {
        margin-bottom: 15px;
    }

    #todas-vacinas .card .icon {
        width: 48px;
        margin-right: 20px;
    }

    #todas-vacinas .card .icon svg {
        max-width: 100%;
    }

    #todas-vacinas .card {
        padding: 20px;
        height: 100px;
    }

    #todas-vacinas ul li p {
        margin-bottom: 0;
    }

    .como-funciona-vacinas .slick-slider  {
        padding-bottom: 39px
    }

    #sobre.sobre-vacinas {
        margin-top: 0px !important;
        padding-top: 0;
    }

    #sobre.sobre-vacinas:before {
        pointer-events: none;
        top: -200px;
    }
}