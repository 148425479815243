
#cobertura {
    background:url(./../../assets/images/cobertura.jpg) no-repeat;
    background-size: cover;
    height: 455px;
    display: flex;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    color:#fff;
}

#cobertura .container-fluid {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

#cobertura span {
    font-size: 14px;
    margin-bottom: 13px;
    display: block;
}

#cobertura h3 {
    font-size: 38px;
    font-weight: 600;
    padding: 0 290px;
    margin-bottom: 0;
    line-height: 52px;
}

#cobertura h3 span {
    font-size: 38px;
    font-weight: 600;
    line-height: 52px;
}

#cobertura .btn-primary {
    margin:50px auto 0;
}

#consulte {
    background:#001F85;
    height: 230px;
    display: flex;
    justify-content: center;
    align-content: center;
}

#consulte figure {
    margin-right: 62px;
}

#consulte .container {
    display: flex;
    align-items: center;
    justify-content: center;
}

#consulte .form-consulte p {
    color:#FFFFFF;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 35px;
}

#consulte .form-consulte input[type="text"] {
    width:384px;
    height: 56px;
    border:0;
    padding:10px 38px;
    border-radius: 50px;
}

#consulte .form-consulte input[type="submit"] {
    background:#FF2763;
    border:0;
    width:210px;
    height: 56px;
    border-radius: 50px;
    margin-left: 8px;
    color: #ffff;
    font-weight: bold;
}

@media (max-width:768px) {

    .regioes {
        padding-bottom: 30px;
    }

    .regioes .card p {
        margin-bottom: 0;
    }

    #cobertura {
        height: auto;
        padding: 50px 0;
        background:url(../../assets/images/cobertura-mobile.png) no-repeat top;
        background-size: cover;
    }

    #cobertura .btn-primary {
        width: 100% !important;
        margin: 20px auto 0;
        padding-top:18px;
    }

    #cobertura .container-fluid {
        padding:20px;
    }
    
    #cobertura span {
        margin-bottom: 0;
    }
    
    #cobertura h3 {
        padding:20px 0;
        font-size: 32px;
        line-height: 40px;
        padding-top: 5px;
    }

    
    #cobertura h3 span {
        font-size: 32px;
        line-height: 40px;
        display: initial;
    }

    #consulte {
        height: auto;
        padding:72px 20px;
    }

    #consulte figure {
        display: none;
    }

    #consulte .container {
        padding:0;
    }

    #consulte .form-consulte p {
        display: flex;
        align-items: center;
        margin-bottom: 50px;
    }

    #consulte .form-consulte p:before {
        content:'';
        width: 100%;
        max-width: 69px;
        height: 69px;
        background:url(../../assets/images/mapa.png) no-repeat;
        background-size: 100%;
        display: block;
        margin-right: 20px;
    }

    #consulte .form-consulte input[type="text"] {
        width: 100%;
        margin-bottom: 24px;
    }

    #consulte .form-consulte input[type="submit"] {
        width: 100%;
        margin-left: 0;
    }

    #regioes .card {
        justify-content: flex-start;
        align-items: center;
        padding: 0 0 0 90px;
        height: auto;
        border: 0;
        box-sizing: border-box;
        box-shadow: none;
        border-radius: 20px;
        flex-direction: inherit;
    }

    #regioes .card svg {
        width: 21px;
        height: 33px !important;
        margin-right: 16px;
        margin-bottom: 0;
    }
}