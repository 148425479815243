#serviceCards .box {
    background: #fff;
    box-shadow: 0px 10px 50px rgb(50 130 228 / 40%);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 222px;
    cursor: pointer;
}

#serviceCards .container {
    max-width: 985px;
}

#serviceCards .box:hover {
    background: #E7F0FF;
}

#serviceCards .box:hover svg path {
    fill:#001F85
}



@media (max-width:768px) { 
    /* .slick-slide > div{
        margin: 0 10px;
    } */

    #serviceCards {
        overflow: hidden;
    }

    #serviceCards .slick-slider .slick-list {
        padding: 0px 0px 40px !important;
    }

    #serviceCards .slick-slider {
        padding:0;
    }

    #serviceCards  #banner .box {
        height: 206px;
    }

    #serviceCards .slick-dots {
        bottom: 7px;
    }
    
    #serviceCards .slick-slide {
        padding:0 10px;
        box-sizing: border-box;
    }

    #serviceCards .slick-dots li button:before {
        color:#D9D9D9;
        opacity: 1;
        font-size: 10px;
    }

    #serviceCards .slick-dots li.slick-active button:before {
        color:#e6265c
    }
}