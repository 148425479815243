#page-sobre #diferenciais {
    background: #FF2763;
}

#pilares {
    background: #FF2763;
    padding: 0px 0 120px;
    position: relative;
    margin-top: 193px;
}

#pilares:after {
    content:'';
    background:url(../../assets/images/pilafter.png) no-repeat top center;
    background-size: cover;
    width: 100%;
    height: 201px;
    display: block;
    position: absolute;
    top: -115px;
}

#pilares .container {
    text-align: center;
    z-index: 1;
    position: relative;
}

#pilares .container span {
    color: #fff;
    font-size: 14px;
}

#pilares .container h3 {
    color: #fff;
    font-size: 38px;
    margin-bottom: 50px;
}

#pilares .card {
    border-radius: 20px;
    padding: 50px 40px;
    min-height: 280px;
    align-items: center;
    justify-content: center;
    box-shadow: 10px 40px 50px #D92255;
}

#pilares .card p {
    margin-bottom: 0;
}

#pilares .card h4 {
    font-size: 28px;
    color: #000;
    font-weight: bold;
    margin-bottom: 30px;
}

#pilares .slick-slider {
    padding-bottom: 40px;
}

#pilares .slick-dots li button:before {
    opacity: .5;
    color: #fff;
    font-size: 10px;
}

#pilares .slick-dots {
    bottom: 0;
}

#pilares .slick-dots li.slick-active button:before {
    color:#001F85;
    opacity: 1;
}

@media (max-width:600px) {
    #pilares {
        margin-top: 93px;
        padding-top: 50px;
        padding-bottom: 70px;
    }
    #pilares:after {
        height: 50px;
        top: -27px;
    }

    #pilares .container h3 {
        font-size: 32px;
        line-height: 40px;
    }

    #pilares .slick-list {
        padding:0 !important;
    }
    #pilares .col-md-4 {
        margin-bottom: 10px;
    }

    #pilares .slick-slide {
        padding: 0 10px;
        box-sizing: border-box;
    }

    #pilares .card {
        height: 350px;
        box-shadow: none;
        padding: 50px 8.5px;
    }

    #pilares p {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 28px;
        text-align: center;
        color: #474747;
        mix-blend-mode: normal;
    }

    #pilares h4 {
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 30px;
        display: flex;
        align-items: center;
        color: #000000;
        mix-blend-mode: normal;
    }
    
}