#convenios {
    padding:100px 0;
}

#convenios .btn {
    max-width: fit-content;
    padding: 0 40px;
    margin: 50px auto 0;
}

@media (max-width:728px) {
    .page-convenios #intro-convenios .container h1 {
        font-size: 32px;
        line-height: 40px;
        max-width: 100%;
        text-align: center;
    }

    #convenios .btn {
        max-width: 384px;
        padding: 0;
    }

    #convenios img {
        margin:10px;
        margin-bottom: 60px;
    }

    #intro-convenios {
        min-height: 436px !important;
        display: flex;
        align-items: center;
        border-radius: 20px;
    }

    .page-convenios #intro-convenios .container {
        padding:20px;
    }

    #intro-convenios .container p {
        text-align: center;
    }

    #page-convenios #serviceCards .box {
        max-width: 100%;
        text-align: center;
    }

    .servicos h5 {
        font-size: 32px;
    }

    .servicos {
        text-align: center;
    }

    #page-convenios #convenios-nao-encontrado .txt-destaque {
        padding-top: 0;
        text-align: center;
    }

    #page-convenios #convenios-nao-encontrado .txt-destaque p {
        max-width: 100%;
        text-align: center;
    }

    #page-convenios #convenios-nao-encontrado h3 {
        font-size: 32px;
        line-height: 40px;
    }

    #page-convenios  #condicoes-pagamento {
        padding-top: 0 !important;
    }

    #convenios-nao-encontrado .txt-destaque p {
        max-width: 100% !important;
        text-align: center;
    }

    #convenios-nao-encontrado #condicoes-pagamento {
        padding-top:0;
    }


    #convenios  .slick-dots li button:before {
        color: #D9D9D9;
        opacity: 1;
        font-size: 10px;
    }
    
    #convenios  .slick-dots li.slick-active button:before {
        color: #001F85
    }

    .page-convenios #como-funciona-interna .slick-dots {
        bottom:0;
    }

    .page-convenios #como-funciona-interna .slick-slider {
        padding-bottom: 20px;
    }

    #convenios-nao-encontrado h3 {
        font-size: 32px !important;
        padding: 0 20px;
    }
}
