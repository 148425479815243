#como-funciona-interna {
    padding: 100px 0 0px;
    background: #fff;
}

#como-funciona-interna .container {
    max-width: 1216px;
}

#como-funciona-interna h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 130%;
    text-align: center;
    color: #171C29;
    margin: 12px 0 80px;
}

#como-funciona-interna span {
    font-size:14px;
    color:#FF2763;
}

#como-funciona-interna .container .row div {
    position: relative;
    text-align: center;
}

#como-funciona-interna .container .row.row-como {
    margin-bottom:0;
}

#como-funciona-interna .container .row div svg path {
    fill:#001F85;
}

#como-funciona-interna .icon {
    width: 98px;
    height: 98px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    z-index: 2;
    margin:0 auto;
    margin-bottom: 15px;
}

#como-funciona-interna .icon .number {
    fill:#fff !important;
}


#como-funciona-interna h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #171C29;
    margin-top: 10px;
    margin-bottom: 15px;
}

#como-funciona-interna p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000;
    padding: 0px 70px;
}

#como-funciona-interna .bx-facilidades::before {
    content: '';
    width: 100%;
    position: absolute;
    top: 49px;
    left: 39%;
    z-index: 1;
    border-top: 2px solid #E7F0FF;
}

#como-funciona-interna .row .bx-facilidades:nth-child(3)::before {
    content:none;
    display: none;
}

#como-funciona-interna .btn-primary {
    margin: 40px auto 80px;
    text-align: center;
}

@media (max-width:600px) {
    #como-funciona-interna .slick-dots li button:before {
        color: #D9D9D9;
        opacity: 1;
        font-size: 10px;
    }
    
    #como-funciona-interna .slick-dots li.slick-active button:before {
        color: #001F85
    }
    
    #como-funciona-interna h3 {
        margin: 12px 0;
    }
    
    #como-funciona-interna .slick-slide {
        margin-top: 3rem;
        padding: 0 10px;
        box-sizing: border-box;
    }
    
    #como-funciona-interna .bx-facilidades {
        height: 337px;
        padding-top: 2rem;
        background: #FFFFFF;
        border-radius: 20px;
        box-shadow: 0px 4px 50px rgba(50, 130, 228, 0.2);
    }
    
    #como-funciona-interna .bx-facilidades::before {
        display: none;
    }

    .page-convenios #como-funciona-interna h4 {
        padding: 0 12%;
    }

    .page-convenios #como-funciona-interna .btn-primary {
        margin-top: 30px !important;
    }
}

@media (max-width:440px) { 
    #como-funciona-interna p {
        font-size: 12px;
    }

    .page-convenios #como-funciona-interna h4 {
        font-size: 20px;
    }

    .page-convenios #como-funciona-interna .btn-primary {
        margin-bottom: 0;
    }
}


