
#condicoes-pagamento {
    padding: 113px 0 50px;
}

#condicoes-pagamento .txt-destaque ul {
    list-style: none;
    margin:0;
    padding:0;
}
#condicoes-pagamento .txt-destaque ul li {
    margin-bottom: 35px;
    font-size: 18px;
    color:#001F85;
    font-weight: 600;
}

#condicoes-pagamento .txt-destaque ul svg {
    margin-right: 10px;
}

#condicoes-pagamento .txt-destaque ul svg path {
    fill:#FF2763;
}

#condicoes-pagamento span {
    font-size:14px;
    color:#FF2763;
}

#condicoes-pagamento img {
    margin-top: 25px;
}

#condicoes-pagamento h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 52px;
    letter-spacing: -0.01em;
    color: #000;
    margin: 12px 0 30px;
}

#condicoes-pagamento p {
    font-size: 18px;
    margin-bottom: 45px;
}

#condicoes .btn {
    width:384px;
    height: 56px;
    color:#fff;
    border-radius: 50px;
    font-weight: 600;
    padding:15px;
    margin:0 auto;
    border:0 !important;
    font-size: 18px;
    letter-spacing: 0;
    display: flex;
    align-items: center;
}

#condicoes .btn:after {
    content:'';
    display: inline-block;
    background:url(./../../assets/svg/arrow.svg) no-repeat;
    width:17px;
    height: 16px;
    margin-left:27px;
    margin-top:3px;
}


#condicoes {
    margin-bottom: 104px;
}

#condicoes .container {
    background: #FF2763;
    border-radius: 16px;
    background-size: cover;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    text-align: center;
    color: #fff;
    max-width: 1008px;
    height: 180px;
    margin:0 auto;
}

#condicoes .container ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    font-size: 18px;
    margin-bottom: 0;
}

#condicoes svg {
    margin-right: 3px;
}

#condicoes svg path {
    fill:#FC2D65;
}

#condicoes ul li {
    margin-right: 45px;
}

#condicoes h4 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 30px;
}

@media (max-width:600px) {
    #condicoes {
        padding:20px;
        margin-bottom: 0;
    }
    #condicoes-pagamento {
        padding: 50px 20px 0px;
    }

    #condicoes-pagamento .txt-destaque {
        text-align: center;
        padding: 20px;
        padding-top:0 !important;
    }

    #condicoes-pagamento h3 {
        font-size: 32px;
        line-height: 40px;
    }

    #condicoes-pagamento .txt-destaque ul {
        text-align: left;
        padding-left: 20%;
    }

    #condicoes .btn {
        width: 100%;
        font-size: 16px;
    }

    #condicoes .container {
        height: auto;
        padding:48px 20px;
    }

    #condicoes-pagamento .img-mobile {
        margin-bottom: 30px;
    }
}