#tabela-diferencas {
    margin-bottom: 100px;
    margin-top: 40px;
}

#tabela-diferencas table {
    border-radius: 50px;
    border:1px solid #3282E4;
}

#tabela-diferencas table tr td {
    border:1px solid #3282E4;
    padding:20px;
    text-align: center;
    font-size: 14px;
    color:#474747;
}

#tabela-diferencas table thead tr td {
    color:#001F85;
    font-size: 16px;
    font-weight: 600;
}

#tabela-diferencas table tr td:first-child {
    color:#FF2763;
    font-size: 16px;
    font-weight: 600;

}

#tabela-diferencas h3 {
    text-align: center;
    font-weight: 600;
    font-size: 38px;
    margin-bottom: 50px;
}

#exames-mais-buscados .card-testes {
    padding:48px;
    border: 2px solid #E7F0FF;
    box-sizing: border-box;
    box-shadow: 0px 10px 50px rgba(50, 130, 228, 0.2);
    border-radius: 20px;
    height: 100%;
    justify-content: space-between;
}

#perguntas-frequentes.todos-testes h3 {
    font-size: 32px;
    text-align: center;
    color:#000;
    font-weight: 600;
    margin-bottom: 100px;
}

#perguntas-frequentes.todos-testes .accordion-item .accordion-button {
    padding:20px 50px 20px 30px;
    font-size: 20px;
    font-weight: bold;
}

#perguntas-frequentes.todos-testes .accordion-item .accordion-button svg {
    margin-right: 30px;
}

#perguntas-frequentes.todos-testes .accordion-body {
    padding: 25px 91px 25px;
}

#perguntas-frequentes.todos-testes .accordion-body h4 {
    font-size: 18px;
    text-align: left;
    color:#000;
    font-weight: bold;
}

#perguntas-frequentes.todos-testes .accordion-body p {
    font-size: 18px;
    text-align: left;
    color:#474747;
    font-weight: normal;
    margin-bottom: 40px;
}

#perguntas-frequentes.todos-testes .accordion-body ul li {
    font-size: 18px;
    margin-bottom: 20px;
}

#sobre.sobre-testes {
    margin-top:0;
    padding-bottom: 80px;
}

#sobre.sobre-testes:before {
    content:'';
    background:url(../../assets/images/pilafter.png);
    width: 100%;
    height: 160px;
    display: block;
    position: absolute;
    top: 0;
    background-size: cover;
}

#sobre.sobre-testes .container {
    padding-top: 130px;
}

#sobre.sobre-testes .row {
    flex-direction: row;
}

#sobre.sobre-testes .btn-secondary {
    background: #001F85;
    max-width: 311px;
}

#sobre.sobre-testes .container img {
    padding-top: 50px;
}

#sobre.sobre-testes:after {
    content:'';
    display: block;
    width: 100%;
    height: 516px;
    position: absolute;
    right: -55%;
    top: 34%;
    background:url(../../assets/images/vector-line.png) no-repeat
}

.agende-testes .btn {
    margin: 50px auto;
}

@media (max-width:600px) {
    #sobre.sobre-testes {
        background-size: cover;
        margin-top: 0 !important;
        padding-top:0 !important;
    }

    #sobre.sobre-testes .btn-secondary {
        margin:0 auto;
    }

    #sobre.sobre-testes .container {
        padding-top:50px;
    }

    #sobre.sobre-testes:after {
        display: none;
    }

    #perguntas-frequentes.faq-testes {
        background:none !important;
        padding-top: 0;
    }

    #perguntas-frequentes.faq-testes .accordion-item .accordion-button {
        background: #FFEAF0;
    }

    #perguntas-frequentes.faq-testes .accordion-item {
        border:0;
        box-shadow: 0px 10px 50px rgb(50 130 228 / 20%);
        border-radius: 20px;
    }

    #perguntas-frequentes.faq-testes .col-12 {
        padding:0;
    }

    #perguntas-frequentes.faq-testes ul {
        list-style: none;
        padding:0;
        margin-bottom: 0;
    }

    #perguntas-frequentes.faq-testes ul li {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #3282E4;
        padding:15px 0;
    }

    #perguntas-frequentes.faq-testes ul li:last-child {
        border-bottom:0;
        padding-bottom: 0;
    }

    #perguntas-frequentes.faq-testes ul li p {
        font-size: 14px;
        line-height: 23px;
        font-weight: normal;
        color: #474747;
        text-align: left;
        margin-left: 20px;
        margin-bottom: 0;
        width: 60%;
    }

    #perguntas-frequentes.faq-testes ul li strong {
        color:#FF2763;
        font-size: 16px;
        text-align: right;
        width: 40%;
    }

    #tabela-diferencas {
        margin-bottom: 0;
    }

    #consultarDisponibilidade.intro-testes p {
        font-size: 18px;
        padding: 0 4%;
        color: #D9D9D9;
    }

    #perguntas-frequentes.todos-testes .accordion-item {
        border: 0;
        box-shadow: 0px 10px 40px rgba(50, 130, 228, 0.2);
    }

    #perguntas-frequentes.todos-testes .accordion-item .accordion-button {
        font-size: 18px;
    }

    #perguntas-frequentes.todos-testes .accordion-item .accordion-button svg {
        width: 43px !important;
    }

    #sobre.sobre-testes:before {
        width: 100%;
        height: 210px;
        display: block;
        position: absolute;
        top: -28px;
        background-size: 100%;
    }

 
    #exames-mais-buscados h3 {
        padding: 0 25px;
    }
    
    #exames-mais-buscados .info {
        margin-bottom: 2rem;
    }

    #exames-mais-buscados .btn .btn-primary {
        font-size: 16px;
    }
}

@media(max-width: 440px) {
    #tabela-diferencas h3 {
        font-size: 32px;
    }

    #exames-mais-buscados.testes-mais-buscados .card {
        height: auto;
        padding:20px;
    }

    #exames-mais-buscados.testes-mais-buscados h2 {
        font-size: 32px;
    }

    #exames-mais-buscados h3 {
        padding: 0;
        font-size: 16px;
    }

    #perguntas-frequentes.todos-testes .accordion-item .accordion-button {
        padding-right: 20px;
    }

    #perguntas-frequentes.todos-testes h3 {
        margin-bottom: 50px;
    }

    #perguntas-frequentes.todos-testes .accordion-body {
        padding: 10px 32px;
    }
}