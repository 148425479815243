.page-area-de-cobertura #condicoes-pagamento .row {
    flex-direction: row-reverse;
}

.page-area-de-cobertura #sobre:before {
    display: none;
}

.page-area-de-cobertura #sobre {
    margin-top:100px;
}

.page-area-de-cobertura #sobre .row {
    flex-direction: row-reverse;
}

.page-area-de-cobertura #sobre img {
    pointer-events: none;
}

.page-area-de-cobertura #sobre .btn-secondary {
    background:#FF2763;
}

.consultar-area .container {
    background:#001F85 url(../../assets/images/mapa1.png) top center no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color:#fff;
    padding: 5% 8% 3%;
    text-align: center;
    border-radius: 0 0 20px 20px;
    max-width: 1332px;
}

.consultar-area span {
    font-size: 14px;
    margin-bottom: 7px;
}

.consultar-area h1 {
    font-size: 46px;
    line-height: 52px;
    font-weight: 600;
    margin-bottom: 50px;
}

.consultar-area h1 span {
    font-size: 46px;
    line-height: 52px;
    font-weight: 600;
    display: block;
}

.consultar-area p  {
    color:#D9D9D9;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 35px;
}

.consultar-area form {
    display: flex;
}

.consultar-area input[type="text"] {
    background:#fff;
    width: 100%;
    min-width: 421px;
    height: 56px;
    padding:15px 38px;
    border:0;
    border-radius: 50px;
    margin-right: 11px;
}

.consultar-area input[type="submit"] {
    padding: 0 50px;
    min-width: 160px;
    height: 56px;
    border:0;
    border-radius: 50px;
    background: #FF2763;
    display: block;
    color:#fff;
}

/* Regiões */

.regioes {
    text-align: center;
    padding: 90px 0;
}

.regioes .card {
    justify-content: center;
    align-items: center;
    padding: 20px;
    height: 220px;
    border: 1px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 10px 50px rgb(50 130 228 / 20%);
    border-radius: 20px;
}

.regioes h3 {
    font-size: 38px;
    color:#000000;
    font-weight: 600;
    margin-bottom: 55px;
}

.regioes .card svg {
    margin-bottom: 25px;
}

.regioes .card p {
    font-size: 20px;
    font-weight: 600;
    color:#000;
}

#resultado-busca-area {
    text-align: center;
}

#resultado-busca-area h4 {
    padding:80px 0;
}

#resultado-busca-area .resultNotFound h4 {
    padding-bottom: 0;
}

#resultado-busca-area .resultNotFound a {
    font-size: 16px;
    color:#FF2763;
    font-weight: bold;
}

@media (max-width:728px) {
    .consultar-area h1, .consultar-area h1 span {
        font-size: 32px;
        line-height: 40px;
    }

    .page-area-de-cobertura header {
        padding-bottom: 0px;
    }

    .regioes .col {
        flex: 100%;
        margin-bottom: 20px;
    }

    .regioes h3 {
        font-size: 32px;
        padding: 0 20px;
    }
}