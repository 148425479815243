#intro-contato {
    padding:100px 0 50px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

#intro-contato:after {
    content:'';
    display: block;
    background:url(../../assets/images/contato.png) no-repeat top center;
    width: 100%;
    top:-350px;
    position: absolute;
    height: 852px;
    z-index: -1;
}

#intro-contato h1 {
    font-size: 46px;
    font-weight: bold;
    margin-bottom: 20px;
}

#intro-contato p {
    font-weight: 500;
    font-size: 18px;
    color:#474747;
    margin-bottom: 25px;
}

#intro-contato a {
    margin:0 auto;
}

#intro-contato .tel {
    font-size: 28px;
    color: #212529;
    margin-bottom: 0;
    margin-top: 22px;
    display: inline-block;
    background:url(../../assets/images/phone.svg) no-repeat 0 5px/14%;
    padding-left: 35px;
}

#form-contato  {
    margin-bottom: 120px;
}

#form-contato .container {
    background:#001F85;
    border-radius: 24px;
    padding:32px;
    max-width: 1008px;
    display: none;
}

#form-contato .info {
    background: #fff;
    border-radius: 24px;
    padding: 72px;
    height: 100%;
}

#form-contato form {
    display: flex;
    flex-direction: column;
    color: #fff;
    padding: 20px 40px;
}

#form-contato input[type="text"] {
    height: 48px;
    border-radius: 8px;
    border: 0;
    margin-bottom: 20px;
}

#form-contato input[type="submit"] {
    margin-top: 30px;
    width: auto !important;
    max-width: 175px;
}

#form-contato svg {
    margin-bottom: 20px;
}

#form-contato label {
    margin-bottom: 11px;
}

#form-contato textarea {
    height: 126px;
    border-radius: 8px;
}

#form-contato p strong {
    font-size: 18px;
    color:#FF2763;
}

#faq-central {
    text-align: center;
}

#faq-central .nav-tabs {
    justify-content: space-between;
    border: 0;
}


#faq-central .titulo-faq  {
    margin-bottom: 80px;
}

#faq-central .titulo-faq span {
    font-size: 14px;
    color:#FF2763;
}

#faq-central .titulo-faq h3 {
    font-size: 38px;
    color:#000;
    font-weight: 600;
    margin-top: 12px;
}

#faq-central .nav-tabs .nav-item.show .nav-link, #faq-central .nav-tabs .nav-link.active {
    border-color: #fff;
    border-bottom: 4px solid #001F85;
    color:#001F85;
    font-weight: bold;
}

#faq-central .nav-tabs .nav-link {
    font-size: 16px;
    color: #474747;
    border:0;
}


#faq-central .nav-tabs .nav-link:hover {
    border:0;
    border-bottom: 4px solid #001F85;
    color:#001F85;
    font-weight: bold;
}

#faq-central #perguntas-frequentes {
    background:#FF2763;
    position: relative;
    padding-top: 64px;
}

#faq-central #perguntas-frequentes h3 {
    display: none;
}

#faq-central #perguntas-frequentes p {
    display: none;
}

#faq-central .accordion-body {
    text-align: left;
}

#faq-central #perguntas-frequentes .accordion-item {
    border:0;
    border-radius: 20px;
}

#faq-central #perguntas-frequentes .accordion-item .accordion-button {
    padding:20px 27px 20px 35px;
    color: #000;
    font-weight: 600;
}


#faq-central #perguntas-frequentes .container {
    position: relative;
}

#faq-central #perguntas-frequentes .container:after {
    content:'';
    background:url(../../assets/images/dots.png) no-repeat;
    width: 168px;
    height: 159px;
    background-size: 100%;
    position: absolute;
    top: 27%;
    left: -22%;
}

#faq-central #perguntas-frequentes .container:before {
    content:'';
    background:url(../../assets/images/dots.png) no-repeat;
    width: 77.14px;
    height: 72px;
    background-size: 100%;
    position: absolute;
    top: -5%;
    right: -42px;
    opacity: 1;
}

.modal-sucesso {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 80%);
    top: 0;
    z-index: 9999999;
    display: none;
    align-items: center;
    justify-content: center;
}

.modal-sucesso .container {
    max-width: 592px;
    background:#fff !important;
    padding: 80px !important;
    text-align: center;
    position: relative;
}

.modal-sucesso .container svg {
    top:20px;
    right: 20px;
}

.modal-sucesso .container p {
    color:#4D4D4F;
}

.modal-sucesso .container a {
    color:#FF2763;
    font-size: 18px;
}

.modal-sucesso h4 {
    color:#001F85;
    font-size: 24px;
}


@media (max-width:768px) {
    #form-contato .row {
        flex-direction: column-reverse;
    }

    #form-contato form {
        padding: 20px 0px;
    }

    #faq-central .accordion-body {
        padding-left:35px;
    }

    #faq-central .nav-tabs .nav-item.show .nav-link, #faq-central .nav-tabs .nav-link {
        background: #E7F0FF;
        border-radius: 50px;
        font-style: normal;
        font-size: 14px;
        color: #3282E4;
        margin-bottom: 2rem;
        height: 54px;
        font-weight: 300;
    }

    #faq-central .nav-tabs .nav-item.show .nav-link, #faq-central .nav-tabs .nav-link.active {
        border: none;
        background: #001F85;
        color: #E7F0FF;
    }

    #intro-contato:after {
        background:url(../../assets/images/contato-mobile.png) no-repeat top center;
        background-size: 113%;
        top:10px;
    }

    #intro-contato h1 {
        font-size: 32px;
        padding: 0 30px;
        font-weight: 600;
    }
    
    #intro-contato p {
        padding: 0 30px;
    }

    #form-contato .info {
        padding: 20px;
    }

    #intro-contato a {
        max-width: 100%;
    }

    #form-contato input[type="submit"] {
        max-width: 100%;
        margin-bottom: 50px;
    }

    #faq-central .titulo-faq {
        padding: 10px 30px 0;
        margin-bottom: 30px;
    }

    #form-contato {
        margin-bottom: 60px;
    }
}