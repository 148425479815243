footer {
    background: #001F85;
    padding: 64px 0 32px;
    position: relative;
    z-index: 99999;
}

footer .container {
    max-width: 1216px;
}

footer h3 {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 100%;
    color: #FFFFFF;
    margin-bottom: 30px;
}

footer p,
footer a {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 130%;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    text-decoration: none;
    margin-bottom: 12px;
    display: block;
}

footer a:hover {
    text-decoration: none;
    color: #fff;
}

footer .redes-sociais {
    position: relative;
    display: none;
}

footer .redes-sociais .itens {
    float: none;
    margin: 20px 0;
    position: relative;
    z-index: 2;
}

footer .redes-sociais .itens a {
    margin-right: 70px;
    display: block;
    float: left;
}

footer .links-secundarios {
    font-size: 14px;
    display: flex;
    color: #fff;
    justify-content: space-between;
    border-top: 1px solid #E7F0FF;
    padding-top: 100px;
    margin-top: 40px;
}

footer .links-secundarios span {
    font-size: 13px;
    padding-top: 0px;
    margin-left: 80px;
}

footer .links-secundarios span a {
    display: block;
    margin-top: 10px;
}

footer .redes-sociais span {
    font-size: 14px;
    margin-left: 0;
}

footer .links-secundarios a {
    font-size: 14px;
    letter-spacing: -1px;
    color: #fff;
    display: inline-block;
    line-height: inherit;
}

footer .logo-footer {
    max-width: 445px;
    padding-top: 35px;
}

footer .logo-footer p {
    line-height: 27px;
    font-size: 16px;
}

footer .logo-footer p.warning {
    line-height: 27px;
    font-size: 14px;
}

footer .logo-footer svg {
    margin-right: 38px;
    margin-bottom: 20px;
}

footer .col-services {
    display: flex;
    justify-content: right;
    gap: 136px;
}

footer .col-services ul {
    list-style: none;
    padding: 0;
}

footer .container-whatsapp {
    display: grid;
    padding: 24px;
    margin: 24px 0px;
    border: 1px solid #FF2763;
    border-radius: 30px;
    gap: 20px;

    span {
        width: 100%;
        height: 100%;

        h3 {
            color: #FFF;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.32px;
            text-transform: uppercase;
        }
    }

    .content-whatsapp {
        display: flex;
        justify-content: space-between;
        .units > p {
            color: #FFF;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.24px;
            list-style-type: none;
        }

        .phones > p {
            color: #FF2763;
            font-size: 12px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: -0.24px;
        }
    }
}

;

footer .col-services ul li a {
    font-size: 18px;
}

footer .col-services ul li a:hover {
    color: #FF7399;
}

footer .apps {
    border: 1px solid #FF2763;
    border-radius: 32px;
    max-width: fit-content;
    padding: 40px;
}

footer .apps h3 {
    font-weight: 700;
    font-size: 18px;
    line-height: 130%;
    color: #FFFFFF;
}

footer .apps .links-app {
    display: flex;
    align-items: center;
    justify-content: center;
}

footer .apps .links-app .link-app {
    margin: 0 10px;
}

footer .apps .links-app .link-app:hover svg path {
    fill: #FF7399;
}

footer .responsaveis {
    color: #ffffff;
}

footer .responsaveis h4 {
    font-size: 12px;
    font-weight: 600;
    margin-bottom: 20px;
}

footer .responsaveis ul {
    list-style: none;
    padding-left: 0;
}

footer .responsaveis ul li {
    font-size: 12px;
    font-weight: 400;
}

footer .responsaveis ul li strong {
    font-size: 12px;
    font-weight: 700;
}

footer .copy {
    color: #ffffff;
    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    display: block;
}

footer .copy a {
    display: inline;
}

footer .copy a:hover {
    color: #FF7399;
}

footer .tel {
    font-size: 28px;
    color: #fff;
    margin-bottom: 0;
    margin-top: 42px;
    display: inline-block;
    background: url(../../assets/images/phone1.svg) no-repeat 0 5px/14%;
    padding-left: 35px;
}

footer .separator-line {
    display: none;
}

@media (max-width:991px) {
    footer .copy {
        text-align: center;
        margin-top: 30px;
    }
}

@media (max-width: 1023px) {
    footer .logo-footer {
        padding-top: 0;
        text-align: center;
        margin-bottom: 30px;
        max-width: 100%;
    }

    footer .col-md-6{
        width: 100%;
    }

    footer .col-12 {
        padding: 0 20px;
        gap: 75px;
        justify-content: center;
    }

    footer .links-secundarios {
        display: block;
        margin-top: 25px;
    }

    footer .links-secundarios span {
        text-align: center;
        display: block;
        margin: 0;
    }

    footer .col-services ul li a {
        font-size: 16px;
        margin-bottom: 17px;
    }

    footer .redes-sociais .itens {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    footer .redes-sociais .itens a:last-child {
        margin-right: 0;
    }

    footer .logo-footer p {
        line-height: 22px;
        font-size: 16px;
        padding: 20px 30px 25px;
    }

    footer .logo-footer svg {
        display: block;
        margin: 0 auto 40px;
    }

    footer .redes-sociais {
        padding-top: 32px;
        padding-bottom: 20px;
    }

    footer .apps {
        margin: 40px auto;
    }

    footer .responsaveis {
        margin:0 auto ;
        max-width: fit-content;
    }

    footer .responsaveis ul li {
        margin-bottom: 10px;
    }

    footer .tel {
        margin-top: 0;
    }

    footer .responsaveis h4 {
        text-align: center;
    }

    footer .separator-line {
        display: block;
        border-top: 1px solid #E7F0FF; 
        width: 100%;
        margin: 42px 0 64px;
    }
}