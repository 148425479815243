#como-funciona-interna.como-funciona-testes {
    padding: 100px 0 0px;
    background: #fff;
    position: relative;
}
#como-funciona-interna.como-funciona-testes:before{
    content:'';
    background:url(./../../../assets/images/como-funciona-testes.png) no-repeat center top / cover;
    width: 100%;
    position: absolute;
    top: -100px !important;
    height: 794px;
    pointer-events: none;
}

#como-funciona-interna .container {
    max-width: 1216px;
}

#como-funciona-interna h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 130%;
    text-align: center;
    color: #171C29;
    margin: 12px 0 80px;
}

#como-funciona-interna span {
    font-size:14px;
    color:#FF2763;
}

#como-funciona-interna .container .row div {
    position: relative;
    text-align: center;
}

#como-funciona-interna .container .row.row-como {
    margin-bottom:0;
}

#como-funciona-interna .container .row div svg path {
    fill:#001F85;
}

#como-funciona-interna .icon {
    width: 98px;
    height: 98px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    z-index: 2;
    margin:0 auto;
    margin-bottom: 15px;
}

#como-funciona-interna .icon .number {
    fill:#fff !important;
}


#como-funciona-interna h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #171C29;
    margin-top: 10px;
    margin-bottom: 15px;
}

#como-funciona-interna p {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #000;
    padding: 0px 70px;
}

#como-funciona-interna.como-funciona-testes .bx-facilidades::before {
    content: '';
    width: 100%;
    position: absolute;
    top: 49px;
    left: 39%;
    z-index: 1;
    border-top: 2px solid #E7F0FF;
}

#como-funciona-interna.como-funciona-testes .row .bx-facilidades:nth-child(3)::before {
    content: '' !important;
    display: block !important;
    width: 100%;
    position: absolute;
    top: 49px;
    left: 39%;
    z-index: 1;
    border-top: 2px solid #E7F0FF;
}

#como-funciona-interna.como-funciona-testes .row .bx-facilidades:nth-child(4)::before {
    display: none !important;
}


#como-funciona-interna .btn-primary {
    margin: 80px auto;
}

#como-funciona-interna.como-funciona-testes p {
    padding: 0px 24px
}

#como-funciona-interna.como-funciona-testes .icon {
    background:none;
}

@media (max-width:600px) {
    #como-funciona-interna .slick-dots li button:before {
        color: #D9D9D9;
        opacity: 1;
        font-size: 10px;
    }
    
    #como-funciona-interna .slick-dots li.slick-active button:before {
        color: #3282E4;
    }
    
    #como-funciona-interna h3 {
        margin: 12px 0;
    }
    
    #como-funciona-interna .slick-slide {
        margin-top: 3rem;
        padding: 0 10px;
        box-sizing: border-box;
    }
    
    #como-funciona-interna .bx-facilidades {
        height: 337px;
        padding-top: 2rem;
        background: #FFFFFF;
        border-radius: 20px;
        box-shadow: 0px 4px 50px rgba(50, 130, 228, 0.2);
    }
    
    #como-funciona-interna .bx-facilidades::before {
        display: none;
    }
}

@media(max-width: 440px) {
    #como-funciona-interna h4 {
        font-size: 20px;
    }
    #como-funciona-interna.como-funciona-testes h4 {
        padding: 0 10px !important;
        font-size: 18px;
    }

    #como-funciona-interna .slick-dots li button:before {
        margin-top: 1rem;
    }
    
    #como-funciona-interna:before {
        top: -150px !important;
        pointer-events: none;
    }

    #como-funciona-interna.como-funciona-testes .slick-slider {
        padding-bottom: 39px;
    }
}