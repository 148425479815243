#sobre {
    position: relative;
    background: rgb(255,39,99);
    background: linear-gradient(0deg, rgba(255,39,99,1) 85%, rgba(255,255,255,1) 15%);
    color:#fff;
    padding-bottom: 139px;
}

#sobre:before {
    background:url(./../../assets/images/shaper-divider.png) no-repeat top center;
    content: '';
    width: 100%;
    background-size: cover;
    height: 698px;
    display: block;
    position: absolute;
    top: -520px;
}

/* #sobre:after {
    background:url(./../../assets/images/sobre.png) no-repeat top center;
    content:'';
    width: 100%;
    height: 480px;
    display: block;
    position: absolute;
    right: -40%;
    bottom: 80px;
} */

#sobre .container {
    z-index: 999999;
    padding-top: 100px;
    position: relative;
    padding-left:104px;
}

#sobre span {
    font-size: 14px;
    margin-bottom: 5px;
    display: block;
}

#sobre h3 {
    font-size: 38px;
    font-weight: 600;
    max-width: 70%;
    line-height: 52px;
    margin-bottom: 15px;
}

#sobre p {
    font-size: 18px;
    line-height: 29px;
    max-width: 85.6%;
    margin-bottom: 30px;
}

#sobre .btn-secondary:after {
    content:'';
    display: inline-block;
    background:url(./../../assets/svg/arrow.svg) no-repeat;
    width:17px;
    height: 16px;
    margin-left:27px;
    margin-top:6px;
}

@media (max-width:768px) {
    #sobre {
        background:#ff2763;
        margin-top: -20px !important;
        padding-top: 70px;
        padding-bottom: 80px;
        text-align: center;
    }
    #sobre .container {
        padding:20px;
    }

    #sobre h3 {
        max-width: 100%;
        font-size: 32px;
        line-height: 40px;
    }

    #sobre:before {
        display: none;
    }

    #sobre .btn-secondary {
        width: 100%;
    }

    #sobre p {
        max-width: 100%;
    }

    #sobre .img-mobile {
        margin: 20px auto 30px;
    }

    #condicoes {
        padding: 20px;
    }
}


@media (min-width:768px) and (max-width:1140px) {
    
    #sobre:before {
        top: -490px;
    }

    #sobre .container {
        padding-left: 0;
    }

    #sobre h3 {
        max-width: 100%;
    }

    #cobertura h3 {
        padding: 0 10%;
    }
}