#ctaApp{
    background-color: #001A70;
    overflow: hidden;
    position: relative;
}
#ctaApp .row-text{
    height: 100%;
}
#ctaApp .col-text h2{
    font-weight: 600;
    font-size: 32px;
    line-height: 125%;
    color: #FFFFFF;
    margin: 0;
}
#ctaApp .col-text p{
    font-weight: 400;
    font-size: 18px;
    line-height: 160%;
    color: #D9D9D9;
    margin: 0;
}
#ctaApp .col-text .btn-primary{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100% !important;
}
#ctaApp .img-fluid{
    display: block;
    margin: 0 auto;
}

@media (max-width:991px) {
    #ctaApp .col-text{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    #ctaApp .col-text p{
        margin: 0 0 30px;
    }
    #ctaApp .img-fluid{
        display: block;
        margin: 54px auto 0;
    }
}

@media (max-width:768px) {
    #ctaApp .col-text{
        padding-top: 60px;
        padding-bottom: 0;
    }
    #ctaApp .img-fluid{
        margin: 30px auto -55px;
    }
}