.txt-featured h3 {
    font-size: 38px;
    line-height: 52px;
    font-weight: 600;
    margin-bottom: 25px;
    max-width: 95%;
}

.txt-featured p {
    font-size: 18px;
    line-height: 29px;
    color: #474747;
}

.txt-featured .row {
    margin-bottom: 50px;
}

.txt-featured .col-txt {
    display: flex;
    flex-direction: column;
    justify-content: center;
}



.txt-featured.img-right-txt-left .col-img {
    text-align: right;
}


.txt-featured.img-right-txt-left .row {
    justify-content: end;
}

#diferenciais .container {
    z-index: 1;
}

/* #diferenciais:after {
    content:'';
    background:url(../../assets/images/waves.png);
    display: block;
    height: 600px;
    width: 100%;
    bottom: -282px;
    position: absolute;
} */

.agende-agora {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 72px;
    z-index: 99999;
    position: relative;
}

#page-sobre #diferenciais:after {
    content:'';
    position: absolute;
    background:url(../../assets/images/waves.png) no-repeat top;
    width: 100%;
    height: 898px;
    top: 380px;
}

#page-sobre #banner br {
    display: none;
}

#page-sobre #banner .container {
    padding-bottom: 0;
}

#page-sobre #banner .container .row {
    height: 100%;
}

#page-sobre .txt-featured {
    padding: 60px 0 0;
}

#page-sobre .img-right-txt-left {
    padding-top: 0;
}

#page-sobre {
    background: url(../../assets/images/hero-sobre.png) no-repeat top center;
    background-position-y: 282px;
    background-size: 100%;
}

@media (max-width:768px) {
    #page-sobre #banner {
        height: 472px;
        display: flex;
        min-height: auto;
        align-items: center;
        justify-content: center;
        background: #001F85 url(../../assets/images/dots-mobile.svg) no-repeat 50px 30px !important;
        margin-top:0;
    }

    #page-sobre #banner p {
        padding:0;
        max-width: 100%;
    }

    #page-sobre  #banner .btn-seta {
        margin: 0 auto;
        display: block;
        text-align: center;
    }

    #page-sobre .txt-featured {
        text-align: center;
        padding:80px 20px 0;
    }

    .row-reverse {
        flex-direction: column-reverse;
    }

    #page-sobre .txt-featured h3 {
        font-size: 32px;
        line-height: 40px;
        font-weight: 600;
        margin-bottom: 25px;
        max-width: 100%;
        padding: 0 30px;
    }

    #page-sobre .txt-featured .container {
        padding:0;
    }

    #page-sobre .txt-featured .col-txt {
        margin-right: 0;
    }

    #page-sobre .img-right-txt-left {
        padding-top: 30px;
    }

    #page-sobre .img-right-txt-left p {
        padding: 0 27px 20px;
    }

    #page-sobre #diferenciais {
        background:none !important;
        margin-top: -20px;
    }

    #page-sobre .agende-agora {
        margin:32px;
        text-align: center;
    }

    #page-sobre .agende-agora a {
        width: 100% !important;
    }
}