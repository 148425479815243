#banner {
    min-height: calc(544px - 56px);
    display: flex;
    position: relative;
    z-index: 1;
    margin-top: 50px;
}

#banner .box {
    background: #fff;
    box-shadow: 0px 10px 50px rgb(50 130 228 / 40%);
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center;
    height: 222px;
    max-width: 312px;
    cursor: pointer;
    margin: 0 auto;
}

#banner .box:hover {
    background: #E7F0FF;
}

#banner .box:hover svg path {
    fill: #001F85
}

#banner .container {
    max-width: 1216px;
}

#banner .banner-box{
    background-color: #001F85;
    border-radius: 20px;
    padding-top: 32px;
}

#banner h1 {
    font-style: normal;
    font-weight: 500;
    font-size: 46px;
    line-height: 120%;
    color: #fff;
    margin-top: 0px;
    margin-bottom: 15px;
}

#banner p {
    font-size: 18px;
    line-height: 23.5px;
    margin-bottom: 24px;
    color: #D9D9D9;
}

#banner .btn-primary {
    width: 280px !important;
    margin-bottom: 52px;
}

#banner .img-banner{
    margin-top: -8px;
}

#banner .row-boxs {
    --bs-gutter-x: 36px;
    position: relative;
    z-index: 2;
    margin-top: -70px;
}

#banner .row-boxs p {
    color: #474747;
    font-size: 16px;
    font-weight: 300;
    padding: 0 80px;
    margin-bottom: 0;
    max-width: 100%;
}

#banner svg path {
    fill: #3282E4;
}

#banner h2 {
    font-size: 28px;
    color: #000;
    padding: 10px 0;
    font-weight: 600;
}

#banner .btn-seta {
    margin: 0 auto;
    cursor: pointer;
    display: block;
    width: fit-content;
}

#banner small {
    font-size: 12px;
    line-height: 18px;
    color: #D9D9D9;
}

#page-como-funciona #banner .img-banner{
    margin-top: 20px;
}

#banner .row-boxs a {
    text-decoration: none;
}

#pageAplicativos #banner .banner-box{
    position: relative;
    height: 430px;
}
#pageAplicativos #banner .banner-box .row{
    height: 100%;
}
#pageAplicativos #banner .banner-box .img-banner{
    position: absolute;
    bottom: 0;
    right: 0;
}

#banner .cards-app{
    margin-top: -50px;
    position: relative;
    z-index: 2;
}
#banner .cards-app .box-app{
    background-color: #ffffff;
    border-radius: 20px;
    box-shadow: 0px 10px 50px rgba(50, 130, 228, 0.2);
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 44px 16px 25px;
    transition: all .4s ease-in-out;
}
#banner .cards-app .box-app:hover{
    background-color: #E7F0FF;
    box-shadow: 0px 10px 50px rgba(50, 130, 228, 0.4);
}
#banner .cards-app .box-app .icone{
    margin-bottom: 7px;
}
#banner .cards-app .box-app h4{
    font-weight: 600;
    font-size: 28px;
    line-height: 42px;
    color: #000000;
}
#banner .cards-app .box-app .btn-primary{
    margin-bottom: 0;
    width: 100% !important;
}


@media (max-width:768px) {
    #banner {
        margin-top: 0;
        max-width: 100%;
        padding: 0 12px;
    }

    #banner .container {
        padding: 24px 12px;
        max-width: 100%;
        margin: 0 auto;
    }

    #banner .banner-box, .container .isMobile{
        padding: 0;
    }

    #banner h1 {
        font-size: 2rem;
        text-align: center;
        max-width: 100%;
        padding: 40px 0 32px;
        margin-bottom: 0;
    }

    #banner .texto{
        padding: 0 12px;
    }

    #banner p {
        text-align: center;
        max-width: 100%;
        margin: 0 auto 24px;
    }

    #banner .btn-seta {
        margin: 0 auto 40px;
    }

    #banner .row-boxs{
        margin-top: 0;
    }

    #banner .box {
        max-width: 100%;
    }

    #banner .btn-primary {
        margin: 0 auto;
        width: 100% !important;
        margin-bottom: 40px;
    }

    #serviceCards .col-md-4 {
        margin-bottom: 20px;
    }

    #page-como-funciona #banner h1 {
        max-width: 100%;
    }
    
    #page-como-funciona #banner p {
        max-width: 100%;
    }

    #page-sobre #banner h1 {
        max-width: 100% !important;
    }

    #pageAplicativos #banner .banner-box{
        background: #001F85 url(../../assets/images/dots-mobile.svg) no-repeat 0 0 !important;
        border-radius: 0;
        height: auto;
    }
    #pageAplicativos #banner h1 {
        text-align: left;
        padding: 64px 0 10px;
    }
    #pageAplicativos #banner p {
        text-align: left;
        padding: 0;
    }
    #banner .cards-app{
        margin: auto;
        background-color: #ffffff;
        border-radius: 20px;
        max-width: 540px;
        padding: 30px 20px;
    }
    #banner .cards-app h3{
        font-weight: 600;
        font-size: 28px;
        line-height: 42px;
        color: #000000;
        text-align: center;
        margin: 0  0 24px;
    }
    #banner .cards-app .btn-primary{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 64px;
        margin-bottom: 24px;
    }
    #banner .cards-app .btn-primary img{
        margin-right: 5px;
    }
    #banner .cards-app .btn-primary:last-of-type{
        margin-bottom: 0;
    }
}

@media (min-width:768px) and (max-width:1140px) {
    #banner .texto{
        padding: 0 0 70px;
    }
    #banner h1{
        padding: 50px 0 10px;
    }
    #banner .row-boxs p {
        padding:0 10px !important;
    }

    #banner .btn-seta {
        margin: 0 auto 40px;
    }

    #banner .box {
        padding:20px;
    }

    #como-funciona h4 {
        font-size: 18px;
    }

    #como-funciona p {
        padding:0;
    }

    #diferenciais .container {
        padding: 104px 50px;
    }

    #diferenciais ul li {
        width: 50%;
    }

    #diferenciais ul li:nth-child(5), #diferenciais ul li:nth-child(6) {
        margin-bottom: 48px !important;
    }

    #pageAplicativos #banner .banner-box{
        height: 430px;
    }
    #pageAplicativos #banner .banner-box .img-banner{
        height: 80%;
    }
}

