#perguntas-frequentes {
    background: url(../../assets/images/faq.png) no-repeat top center;
    background-size:100%;
    padding-top: 150px;
    padding-bottom: 50px;
}

#perguntas-frequentes .container {
    max-width: 1080px;
    margin: 0 auto;
}

#perguntas-frequentes h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: -0.01em;
    color: #FF2763;
    text-align: center;
    margin-bottom: 10px;
}

#perguntas-frequentes p {
    text-align: center;
    font-size: 38px;
    color: #000;
    font-weight: 600;
    margin-bottom: 55px;
}


#perguntas-frequentes .accordion-item {
    margin-bottom: 20px;
    border-radius: 10px;
    border: 1px solid #001F85;
}

#perguntas-frequentes .accordion-item .accordion-button {
    padding: 20px 50px;
    border-radius: 20px;
    font-weight: 600;
}

#perguntas-frequentes .accordion-item .accordion-button:not(.collapsed) {
    color: #000;
    font-weight: 600;
}

#perguntas-frequentes .accordion-button:not(.collapsed) {
    background: none;
    padding-bottom: 0;
    box-shadow: none;
}

.accordion-button:focus {
    border-color: none;
    box-shadow: none
}

.accordion-button::after {
    background: url(../../assets/images/down-faq.png) no-repeat center;
    width: 40px;
    height: 40px;
}

.accordion-button:not(.collapsed)::after {
    background: url(../../assets/images/down-faq.png) no-repeat center;
    transform: rotate(-180deg);
}

.accordion-body {
    padding: 4px 35px 25px 50px;
    color: #474747;
}

.accordion-body h5 {
    font-size: 16px;
    margin-top: 20px;
    color: #474747;
    font-weight: 600;
}

@media (max-width:768px) {
    #perguntas-frequentes {
        padding: 80px 20px 50px;
    }

    #perguntas-frequentes .container {
        padding:0;
    }

    #perguntas-frequentes .accordion-item .accordion-button {
        padding:20px 23px 20px 19px;
    }

    #perguntas-frequentes p {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 40px;
    }

    #perguntas-frequentes .accordion-body {
        padding:0px 20px 25px 20px;
        text-align: left;
    }
}