#page-como-funciona {
    background:url(../../assets/images/fundo-page-como-funciona.png) no-repeat top center;
    background-size:100%;
    background-position-y: 250px;
}

#page-como-funciona #sobre:before {
    display: none;
}

#page-como-funciona #perguntas-frequentes {
    position: relative;
    padding-top:0;
    background:none;
    margin-top: -150px;
}

#page-como-funciona #perguntas-frequentes .container {
    position: relative;
    z-index: 9;
}

#page-como-funciona #perguntas-frequentes:after {
    display: none;
}

#page-como-funciona #como-funciona-interna:before {
    display: none;
}

#sobre.light {
    background:#fff;
}

#sobre.light::after {
    background:url(../../assets/images/waves2.png) no-repeat top center;
    background-size:cover;
    content:'';
    width:100%;
    height: 425px;
    position: absolute;
    top: -50px;
}

#sobre.light span {
    color:#FF2763;
}

#sobre.light h3 {
    color:#000;
}

#sobre.light p {
    color:#7E868A;
}

@media (max-width:768px) {
    #page-como-funciona #perguntas-frequentes {
        margin-top: 50px;
    }

    #page-como-funciona #como-funciona-interna .slick-dots {
        bottom: 65px;
    }

    #page-como-funciona #sobre.light {
        padding-bottom: 30px;
    }

    #page-como-funciona #padrao-atendimento h3 {
        padding: 0 50px;
        margin-bottom: 20px;
    }
}