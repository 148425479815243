#como-agendar {
    background-color: #ff2763;
    padding: 64px 0;
    position: relative;
}

#como-agendar::before,
#como-agendar::after {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    content: "";
    left: 0;
    position: absolute;
    width: 100%;
}

#como-agendar::before {
    background-image: url('../../assets/images/exames-waves.webp'); 
    height: 40px; 
    top: -40px; 
}

#como-agendar::after {
    background-image: url('../../assets/images/waves-white.webp'); 
    bottom: 0px; 
    height: 73px; 
    left: 0;
}

#como-agendar .container {
    color: #fff;
    margin: 0 auto;
    max-width: 1320px;
    position: relative;
}

#como-agendar .container .dots {
   width: 126px;
   height: 118px;
   background-image: url('../../assets/images/dots.png'); 
   position: absolute;
   left: 124px;
   top: -10px;
}


#como-agendar .container h3 {
    font-size: 2.375rem;
    font-weight: 500;
    margin: 12px auto 32px;
}

#como-agendar p {
    font-size: 1.125rem;
    font-weight: 300;
    margin: 0 auto;
    max-width: 920px;
}

#como-agendar span {
    font-size: 0.875rem;
}

#como-agendar .brands {
    align-items: center;
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 60px auto;
    max-width: 1216px;
}

#como-agendar .brands img {
    cursor: pointer;
    transition: .3s;
}

#como-agendar .brands img:hover {
    transform: scale(1.1);
}


@media(max-width: 768px){
    #como-agendar {
        padding: 56px 0;
    }

    #como-agendar p {
        padding: 12px;
    }

    #como-agendar .container .dots {
        width: 85px;
        height: 80px;
        left: 12px;
        top: -50px;
     }

    #como-agendar .brands {
        margin: 32px auto;
        justify-content: space-around;
        gap: 40px;
        flex-wrap: wrap;
    }

    #como-agendar .brands img {
       width: 120px;
       height: auto;
    }

}