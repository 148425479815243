a {
  text-decoration: none;
}

.btn-primary {
    background:#FF2763;
    text-decoration: none;
    max-width: 384px;
    width: 100%;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    border: 0;
    border-radius: 50px;
    font-weight: bold;
    font-size: 18px;
  }
  
  .btn-primary:hover {
    background:#E6265C;
  }
  
  .btn-secondary {
    background:#001F85;
    text-decoration: none;
    width: 384px;
    height: 56px;
    border-radius: 50px;
    color: #fff;
    display: flex;
    align-content: center;
    justify-content: center;
    padding: 15px;
    letter-spacing: 0.7px;
    font-weight: bold;
  }
  
  
  .btn-secondary:hover {
    background:#001A70;
  }

  .slick-dots {
    left:0;
  }