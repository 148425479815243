#vacina-detalhes .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  padding: 5% 8% 3%;
  border-radius: 0 0 20px 20px;
  max-width: 1332px;
  margin-bottom: 51px;
  text-align: center;
  background: #001F85;
  color: #FFFFFF;
}

#vacina-detalhes h1 {
  font-weight: 700;
  font-size: 46px;
  line-height: 44px;

  margin-bottom: 40px;

  text-align: center;
}

#vacina-detalhes p {
  font-size: 20px;
  line-height: 28px;
  font-weight: 400;
  letter-spacing: 0.005em;
  text-align: center;
  padding: 0 15%;
  margin-bottom: 50px;
}

#perguntas-vacina {
  margin-bottom: 40px;
}

#perguntas-vacina .container {
  padding: 0 100px;
}

#perguntas-vacina .container .perguntas h4 {
  margin-bottom: 8px;
}

#perguntas-vacina .container .perguntas p {
  margin-bottom: 55px;
}

#perguntas-vacina .btn-primary {
  width: 580px !important;
  text-align: center;
}

#wrapper-saudemob {
  background:url(../../assets/images/waves2.png) no-repeat top center;
  padding-top: 150px;
  text-align: center;
}

#wrapper-saudemob h1 {
  font-weight: 600;
  font-size: 38px;
  line-height: 52px;
  margin-bottom: 32px;
}

#wrapper-saudemob strong {
  font-size: 20px;
  line-height: 30px;
}

#wrapper-saudemob .requisitos {
  font-size: 14px;
  line-height: 21px;
}

#wrapper-saudemob .lista {
  text-align: left;
  width: 55%;
}

#wrapper-saudemob .lista li {
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  
  letter-spacing: 0.005em;
  padding-bottom: 15px;
  
  color: #474747;
}

.btn-primary {
  z-index: 100;
}

@media (max-width: 600px) {
  #perguntas-vacina .container {
    padding: 0 40px;
  }

  #perguntas-vacina .btn-primary {
    width: 90% !important;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  #wrapper-saudemob .lista {
    width: 95%;
  }

  #wrapper-saudemob {
    margin-bottom: 60px;
  }
}