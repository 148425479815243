#consultarDisponibilidade .container {
    background:#001F85;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color:#fff;
    padding: 5% 8% 3%;
    text-align: center;
    border-radius: 0 0 20px 20px;
    max-width: 1332px;
    margin-bottom: 82px;
}

#consultarDisponibilidade h1 {
    font-size: 46px;
    margin-bottom: 15px;
}

#consultarDisponibilidade p {
    font-size: 20px;
    padding: 0 25%;
    text-align: center;
    margin-bottom: 50px;
}

#consultarDisponibilidade form {
    display: flex;
}

#consultarDisponibilidade input[type="text"] {
    background: #fff;
    width: 100%;
    min-width: 421px;
    height: 56px;
    padding: 15px 38px;
    border: 0;
    border-radius: 50px;
    margin-right: 11px;
}

#consultarDisponibilidade input[type="submit"] {
    padding: 0 50px;
    min-width: 210px;
    height: 56px;
    border: 0;
    border-radius: 50px;
    display: block;
    color: #fff;
    font-weight: bold;
    letter-spacing: 0.5px;
    background:#FF2763 url(./../../assets/images/arrow.png) no-repeat;
    background-position: right 45px top 20px;
}

#exames-mais-buscados {
    text-align: center;
}

#exames-mais-buscados h2 {
    margin-bottom: 47px;
    font-weight: 600;
}

#exames-mais-buscados .card-exames {
    padding:48px;
    border: 2px solid #E7F0FF;
    box-sizing: border-box;
    box-shadow: 0px 10px 50px rgba(50, 130, 228, 0.2);
    border-radius: 20px;
    height: 100%;
    justify-content: space-between;
    display: flex;
    flex-direction: column;
}

#exames-mais-buscados h3 {
    font-size:26px;
    color:#001F85;
    font-weight: 600;
    margin-bottom: 20px;
}

#exames-mais-buscados p {
    text-align: center;
    color:#474747;
    font-size: 16px;
    margin-bottom: 30px;
}

#exames-mais-buscados .btn {
    width: 100% !important;
}

#exames-mais-buscados .col-md-4 {
    padding:0 16px;
}

#exames-mais-buscados .todos-exames {
    font-size: 18px;
    color:#001F85;
    font-weight: 600;
    display: block;
    margin:45px 0;
}

#sobre.sobre-exames {
    margin-top: 100px;
}

#sobre.sobre-exames .container .row {
    flex-direction: initial;
}

#sobre.sobre-exames .btn-secondary {
    background:#001F85;
}

#sobre.sobre-exames:before {
    content:'';
    width: 100%;
    display: block;
    position:absolute;
    background:url(../../assets/images/exames-waves.png) no-repeat top center;
    background-size:cover;
    height: 313px !important;
    top:-209px;
}

#todos-exames {
    position: relative;
    z-index: 1;
}

#todos-exames h2 {
    text-align: center;
    font-weight: 600;
    margin-bottom: 50px;
}

#todos-exames svg {
    margin-top: 10px;
}

#todos-exames ul {
    list-style: none;
}

#todos-exames ul li {
    display: flex;
    border-bottom: 1px solid #E7F0FF;
    padding:30px 150px 10px;
}

#todos-exames ul li p {
    display: flex;
    flex-direction: column;
    margin-left: 30px;
    color:#474747;
}

#todos-exames ul li p strong {
    font-size: 22px;
    font-weight: 600;
    color:#000;
}

#todos-exames ul li p .link-button {
    font-size: 16px;
    font-weight: 600;
    color: #FF2763;
    opacity: .9;
    margin-top: 1rem;
}

#todos-exames ul li p .link-button:hover {
    opacity: 1;
}

#todos-exames .agende-agora .btn {
    margin: 0 auto;
}

#todos-exames ul li:last-child {
    border-bottom: 0;
}

@media (max-width:991px) {
    #exames-mais-buscados .card-exames{
        padding: 30px 15px;
    }
}

@media (max-width:728px) {
    .introExames p {
        color:#D9D9D9;
        font-size: 18px !important;
        line-height: 27px;
        padding: 0 8% !important;
    }
    #sobre.sobre-exames:before {
        top: -165px;
        pointer-events: none;
    }
    #exames-mais-buscados .col-md-4 {
        margin-bottom: 15px;
        padding:0;
    }

    #exames-mais-buscados .slick-dots li button:before {
        color: #D9D9D9;
        opacity: 1;
        font-size: 10px;
    }
    
    #exames-mais-buscados .slick-dots li.slick-active button:before {
        color: #3282E4;
    }
    
    #exames-mais-buscados h3 {
        margin: 12px 0;
        font-size: 18px;
    }
    
    #exames-mais-buscados .slick-slide {
        margin-top: 3rem;
        padding: 0 10px;
        box-sizing: border-box;
    }
    
    #exames-mais-buscados .card-exames {
        height: auto;
        padding:20px;
        background: #FFFFFF;
        border-radius: 20px;
        box-shadow: 0px 4px 50px rgba(50, 130, 228, 0.2);
    }

    #exames-mais-buscados .slick-list {
        padding:0 !important;
    }

    #consultarDisponibilidade h1 {
        font-size: 32px;
    }

    #todos-exames .agende-agora .btn {
        width: 100% !important;
    }

    #todos-exames ul li {
        padding: 30px 0px 10px;
    }

    .agende-agora {
        margin:12px 0;
    }

    #como-funciona-interna .como-funciona-exames{
        background:url(../../assets/images/waves-como-mobile.png) no-repeat top center;
        padding-top: 40px !important;
    }

    #como-funciona-interna .como-funciona-exames .container {
        padding-top:50px
    }

        #exames-mais-buscados .slick-slider {
        padding-bottom: 50px;
    }

    #exames-mais-buscados .slick-slider .slick-dots {
        bottom: 0;
        left:0;
    }

    .como-funciona-exames .slick-slider {
        padding-bottom: 50px;
    }
}

@media (max-width:440px) {
    #exames-mais-buscados .card-exames {
        min-height: 230px;
    }
    #exames-mais-buscados p {
        font-size: 12px;
        margin-bottom: 0;
    }

    #exames-mais-buscados .info {
        margin-bottom: 0 !important;
    }

    .btn-primary {
        margin-top: 0;
        font-size: 14px;
    }

    #exames-mais-buscados h2 {
        margin-bottom: 0;
    }

    #exames-mais-buscados .btn {
        height: 40px;
        padding: 10px;
    }

    #como-funciona-interna.como-funciona-exames h4 {
        font-size: 20px;
    }
}