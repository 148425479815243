.servicos {
    background:url(../../assets/images/waves-service.png) no-repeat top center;
    min-height: 416px;
    padding-top: 50px;
    padding-bottom: 50px;
    text-align: center;
}

.servicos .container {
    position: relative;
    max-width: 1020px;
}

.servicos .container:before {
    content:'';
    background:url(../../assets/images/dots.png) no-repeat;
    width: 126px;
    height: 118px;
    position: absolute;
    top: -12px;
    left: 124px;
}

.servicos span {
    color:#fff;
    font-size: 14px;
}

.servicos h5 {
    font-size: 38px;
    color:#fff;
    margin-top: 17px;
    margin-bottom: 55px;
}


.servicos  h2 {
    font-size: 28px;
    color: #000;
    padding: 10px 0;
    font-weight: 600;
}

.servicos .row-boxs p {
    color: #474747;
    font-size: 16px;
    font-weight: 300;
    padding: 0 80px;
    margin-bottom: 0;
}

@media (max-width:728px) {
    #serviceCards .box {
        margin: 0 auto;
    }

    .servicos .container:before {
        width: 59px;
        height: 56px;
        top: -30px;
        left: 20px;
    }

    .servicos h2 {
        font-size: 20px;
    }

    .servicos .row-boxs p {
        padding: 0 60px;
    }
}

@media (max-width:440px) {
    .servicos .row-boxs p {
        padding: 0 40px;
    }
}