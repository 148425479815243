#como-funciona {
    padding: 80px 0;
    background: #fff;
}

#como-funciona .container {
    max-width: 1216px;
}

#como-funciona h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 130%;
    text-align: center;
    color: #171C29;
    margin: 12px auto 40px;
    max-width: 592px;
}

#como-funciona span {
    font-size: 14px;
    color: #FF2763;
}

#como-funciona .container .row div {
    position: relative;
    text-align: center;
}

#como-funciona .container .row div svg path {
    fill: #001F85;
}

#como-funciona .icon {
    width: 98px;
    height: 98px;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    z-index: 2;
    margin: 0 auto;
}

#como-funciona .icon .number {
    fill: #fff !important;
}


#como-funciona h4 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -0.01em;
    color: #171C29;
    margin-top: 20px;
    margin-bottom: 15px;
}

#como-funciona p {
    color: #7E868A;
    font-size: 1.125rem;
    margin: 0 auto;
    max-width: 800px;
}

#como-funciona .bx-facilidades::before {
    content: '';
    width: 100%;
    position: absolute;
    top: 49px;
    left: 15px;
    z-index: 1;
    border-top: 2px solid #E7F0FF;
}

#como-funciona .btn-primary {
    margin: 60px auto;
}

@media (max-width: 768px) {
    #como-funciona {
       width: 100%;
       padding: 48px 12px 120px;
}

    #como-funciona .container{
        padding: 0 12px ;
        max-width: 100%;
    }

    #como-funciona .btn-primary {
        width: 100% !important;
        margin-top: 30px;
        padding-top:18px;
    }

    #como-funciona .slick-dots li button:before {
        color: #D9D9D9;
        opacity: 1;
        font-size: 10px;
    }

    #como-funciona .slick-dots li.slick-active button:before {
        color: #001f85;
    }

    #como-funciona h3 {
        margin: 12px auto 32px;
        font-size: 2rem;
        line-height: 40px;
    }

    #como-funciona h4 {
        font-size: 18px;
        padding: 0 24px;
    }

    #como-funciona .slick-slide {
        margin-top: 1rem;
        padding: 0 10px;
        box-sizing: border-box;
    }

    #como-funciona .bx-facilidades {
        height: 337px;
        padding-top: 2rem;
        background: #FFFFFF;
        border-radius: 20px;
        box-shadow: 0px 4px 50px rgba(50, 130, 228, 0.2);
    }

    #como-funciona .bx-facilidades::before {
        display: none;
    }
}