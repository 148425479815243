#padrao-atendimento {
    background:url(../../assets/images/waves-atendimento.png) no-repeat;
    background-size: cover;
    background-position: top;
    text-align: center;
    padding:110px 0 333px;
    position: relative;
}

#padrao-atendimento .container:before {
    content:'';
    background:url(../../assets/images/dots.png) no-repeat;
    width: 126px;
    height: 118px;
    position: absolute;
    top:0px;
    left: 124px;
}


#padrao-atendimento span {
    font-size: 14px;
    color:#fff;
    margin-bottom: 12px;
}

#padrao-atendimento .col-md-4 {
    padding:0 16px;
}


#padrao-atendimento h3 {
    font-size: 38px;
    color:#fff;
    margin-bottom: 60px;
}

#padrao-atendimento h4 {
    font-size: 28px;
    color:#000000;
}

#padrao-atendimento .card {
    padding:30px 48px;
    text-align: left;
    border-radius: 20px;
    box-shadow: 0px 10px 50px rgba(74, 19, 46, 0.2);
}

#padrao-atendimento .card .icon {
    margin-bottom: 20px;
    height: 54px;
    display: flex;
    align-items: center;
}

#padrao-atendimento .card h4 {
    font-size: 28px;
    font-weight: bold;
    max-width: 100%;
    margin-bottom: 25px;
}

#padrao-atendimento .card h4 span {
    display: block;
    font-size: 28px;
    font-weight: bold;
    color:#000;
}

#padrao-atendimento .card p {
    font-size: 15px;
    line-height: 28px;
    max-width: 100%;
}

#padrao-atendimento:after {
    content:'';
    display: block;
    position: absolute;
    bottom:-114px;
    background: url(../../assets/images/waves-fundo-atendimento.png) no-repeat;
    width: 100%;
    height: 384px;
    z-index: 1;
}

#padrao-atendimento .container {
    position: relative;
    z-index: 9;
}

.row-padrao-mobile {
    display: none;
}

@media (max-width:728px) {
    #padrao-atendimento {
        padding: 100px 0 50px;
    }
    #padrao-atendimento .container:before {
        background:url(../../assets/images/dots-mobile.svg) no-repeat;
        width: 58px;
        height: 54px;
        position: absolute;
        top: -40px;
        left: 30px;
    }

    #page-como-funciona #padrao-atendimento {
        margin-top: -20px;
    }

    #page-como-funciona #padrao-atendimento .card {
        text-align: center;
    }

    #padrao-atendimento .card h4 {
        max-width: 100%;
    }

    #padrao-atendimento h3 {
        font-size: 32px;
        line-height: 40px;
        padding: 0 70px;
    }

    .row-padrao-desktop {
        display: none;
    }

    .row-padrao-mobile {
        display: block;
    }

    .row-padrao-mobile .accordion-item {
        margin-bottom: 24px;
        border-radius: 20px;
    }

    .row-padrao-mobile .accordion-button {
        border-radius: 20px !important;
    }

    .row-padrao-mobile .accordion-button p {
        width: 100%;
        margin-bottom: 0;
        font-weight: 16px;
        font-weight: 600;
        color:#000;
        text-align: center;
    }

    #padrao-atendimento:after {
        bottom: -334px;
    }

    #padrao-atendimento .accordion-button:not(.collapsed) {
        background-color: #fff;
        box-shadow: none;
    }

    #padrao-atendimento .accordion-body {
        padding: 10px 20px 25px 20px;
        text-align: left;
    }

    #padrao-atendimento .accordion {
        padding:20px;
    }
}