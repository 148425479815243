
.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
    padding:0;
}

.navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .show>.nav-link {
    font-weight: bold;
}


.gtm {
    display:none;visibility:hidden
}

header {
    background: var(--color-variable-theme);
    height: 80px;
    position: relative;
    z-index: 2;
}

header .container {
    max-width: 1216px;
}

header .navbar-collapse {
    justify-content: end;
    padding-top:7px;
}
header .nav-item a {
    color: #000 !important;
}

header .navbar-brand{
    margin-right: 3.2rem;
    padding-top:0;
}

header .navbar {
    padding-top:30px;
}

header .navbar .navbar-nav .nav-link{
    padding-right: 1.3rem;
    padding-left: 1.3rem;
    display: flex;
    align-items: center;
    font-size:14px;
    cursor: pointer;
} 

header .navbar-nav a:hover {
    text-shadow: 0 0 0.02px black;
}

header .navbar-nav .sub-voce,
header .navbar-nav .sub-sobre,
header .navbar-nav .nav-resultados {
    position: relative;
}

header .navbar-nav .sub-voce:hover ul,
header .navbar-nav .sub-sobre:hover ul,
header .navbar-nav .nav-resultados:hover ul {
    display: block;
}

header .navbar-nav .sub-voce ul {
    background:#fff;
    position: absolute;
    list-style: none;
    padding: 10px 18px;
    border-radius: 0 0 10px 10px;
    width: 78%;
    left: 10%;
    display: none;
}

header .navbar-nav .dropdown ul {
    background:#fff;
    list-style: none;
    padding: 10px 18px;
    border-radius: 0 0 10px 10px;
    width: 180px;
}


header .nav-resultados ul {
    background: #fff;
    position: absolute;
    list-style: none;
    padding: 26px 18px 10px;
    border-radius: 0 0 10px 10px;
    width: 100%;
    left: 0;
    top: 28px;
    z-index: 1;
    display: none;
}


header .navbar-nav .dropdown ul li a{
    text-decoration: none;
    font-size:14px;
    color:#474747;
}

header .navbar-nav .dropdown ul li a:hover {
    color:#000;
}


header .nav-resultados .nav-link {
    background: #fff;
    border: 1px solid #001F85;
    border-radius: 100px;
    max-width: 204px;
    height: 41px;
    font-size: 14px;
    font-weight: 600;
    color:#000 !important;
    padding: 0 30px 0 15px !important;
    position: relative;
    z-index: 2;
}
header .nav-resultados .nav-link:after {
    content:'';
    display: inline-block;
    background:url(../../../src/assets/images/arrow-down.png) no-repeat;
    width: 8px;
    height: 5px;
    position: absolute;
    top: 17px;
    right: 15px;
}

header .nav-resultados .nav-link:hover {
    background: #F4F8FF;
}

header .nav-resultados ul li a {
    text-decoration: none;
    font-size: 14px;
}

header .nav-resultados ul li {
    margin-bottom: 10px;
}

header .nav-resultados ul li a:after {
    display: none;
}

header .nav-agende {
    margin-left: 14px;
}

header .nav-agende a {
    background: #FF2763;
    border-radius: 100px;
    color: #fff !important;
    width: 155px;
    justify-content: center;
    font-size: 12px;
    font-weight: bold;
    height: 41px;
}

header .nav-agende a:hover {
    background:#E6265C;
}

header .nav-app {
    margin-left: 14px;
}

header .nav-app a {
    background: #3282E4;
    border-radius: 100px;
    color: #fff !important;
    width: 125px;
    justify-content: center;
    font-size: 12px !important;
    line-height: 117%;
    font-weight: bold;
    height: 41px;
    padding: 0;
}
header .nav-app a:hover{
    background: #2d74cb;
}
header .nav-app a img{margin-right: 5px;}

header .nav-agende a:hover {
    background:#E6265C;
}

header .nav-resultados {
    margin-left: 12px;
}



header .dropdown-toggle:after {
    content:'';
    display: inline-block;
    background:url(../../../src/assets/images/arrow-down.png) no-repeat;
    width: 8px;
    height: 5px;
    margin-left: 10px;
    border: none;
}


.page-area-de-cobertura header .container {
    max-width: 1332px;
    padding:0 58px;
    background:#001F85 !important;
}

.page-area-de-cobertura header .nav-resultados .nav-link {
    color:#000 !important
}

.page-area-de-cobertura header .nav-item a {
    color:#fff !important;
}

.page-area-de-cobertura header .dropdown li a {
    color:#000 !important
}

.page-area-de-cobertura header .nav-resultados a {
    color:#000 !important;
}

.logo-mobile {
    padding:30px 20px;
    display: none;
}


#navbar-nav-desktop {
    display: block !important;
}
#navbar-nav-mobile {
    display: none !important;
}

.whatsfixed {
    position: fixed;
    right: 30px;
    bottom: 14px;
    z-index: 999999999;
}

@media (max-width:1199px) {
    .logo-mobile {
        display: block;
    }
    header .navbar-collapse {
        background:#001F85;
        z-index: 9999;
        height: 100vh;
        top:0;
        position: fixed;
        width: 100%;
        left: 0;
    }

    .navbar-toggler {
        z-index: 999999;
        right: 20px;
        top: 20px;
        border: 0;
        height: 40px;
        width: 40px;
        background:url(../../assets/images/menu.svg) no-repeat;
    }

    header .navbar-nav .dropdown ul {
        background: #FF7399;
        width: 100%;
        margin-left: 20px;
        max-width: 180px;
        border-radius: 0px 20px;
    }

    .page-area-de-cobertura header .container {
        padding:20px;
    }

    .page-area-de-cobertura .navbar {
        padding:0;
    }

    header {
        height: auto;
        padding-bottom: 30px;
    }

    header .container {
        padding:0 20px;
    }

    .navbar-toggler[aria-expanded="true"] {
        width: 40px;
        height: 40px;
        background:url(../../assets/images/closed.svg) no-repeat center;
        position: fixed;
    }

    .navbar-light .navbar-toggler-icon {
        display: none;
    }

    header .nav-resultados ul li a {
        color: #000 !important;
    }
    header .nav-resultados .nav-link:after {
        top: 25px;
        right: 25px;
    }

    header .nav-item a {
        color:#fff !important;
    }

    button:focus:not(:focus-visible) {
        box-shadow: none;
    }

    header .nav-resultados {
        margin:20px 20px 0;
    }

    header .nav-resultados .nav-link {
        max-width: 100%;
        justify-content: center;
        height: 56px;
    }

    header .nav-agende, header .nav-app {
        margin: 20px 20px 0;
    }
    
    header .nav-agende .nav-link {
        max-width: 100%;
        width: 100%;
        height: 56px;
    }

    header .nav-app a {
        max-width: 100%;
        width: 100%;
        height: 56px;
    }

    header .navbar .navbar-nav .nav-link {
        padding: 16px 20px;
    }
 
    header .sub:after {
        background: url(../../assets/images/sub.svg) no-repeat;
    }

    header .sub-testes {
        display: none;
    }

    .page-area-de-cobertura header .sub-sobre li a, .page-area-de-cobertura header .sub-voce li a {
        color:#fff !important;
        padding: 14px;
        display: block;
        text-align: center;
    }

    header .navbar-nav .sub-voce ul li a, header .navbar-nav .sub-sobre ul li a {
        padding: 14px;
        display: block;
        text-align: center;
    }

    header .navbar-nav .sub-sobre ul {
        background: #FF7399;
        position: relative;
        width: 100%;
        left: 20px;
        max-width: 246px;
        border-radius: 0px 20px;
    }
    
    #navbar-nav-desktop {
        display: none !important;
    }
    #navbar-nav-mobile {
        display: block !important;
    }

    header .nav-resultados ul {
        top:35px;
    }
}

@media (min-width:1199px) and (max-width:1220px) {
    header .navbar .navbar-nav .nav-link {
        padding-right: 1rem;
        padding-left: 1rem;
        font-size: 12px;
    }

    header .nav-agende a {
        width:140px;
    }
}