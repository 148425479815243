#politicaDePrivacidade .container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  background:#001F85;
  text-align: center;
  color:#FFFFFF;
  
  padding: 5% 8% 3%;
  border-radius: 0 0 20px 20px;
  max-width: 1332px;
  margin-bottom: 82px;
}

#politicaDePrivacidade h1 {
  font-size: 46px;
  margin-bottom: 15px;
}

#politicaDePrivacidade p {
  font-size: 20px;
  padding: 0 25%;
  text-align: center;
  margin-bottom: 50px;
}

#intro .title {
  margin-bottom: 20px;
}

#intro .description {
  margin-bottom: 50px;
}

#intro .description p {
  color: #474747;
}

#intro .description ol li,
#intro .description ul li {
  color: #474747;
  padding: 5px;
}